import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import * as Sentry from "@sentry/vue";

const app = createApp(App);
import VueTheMask from 'vue-the-mask';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
  Sentry.init( {
    app,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    environment: process.env.NODE_ENV,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/events\.puntacana\.com/,
      /^https:\/\/bookings\.puntacana\.org/
  ],  });
}

app.config.globalProperties.$dayjs = dayjs;

app.use(store);
app.use(VueTheMask)
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.mount('#app');
