<template>
  <div class="container-fluid" style="width: 36rem; margin-top: 4rem;">
    <div style="text-align: center;" class="mb-4">
      <img src="../assets/expired_link.png" class="card-img-top-linked-expired" alt="...">
    </div>
    <div class="card-body">
      <h5 class="card-title">Oops, this link has expired.</h5>
      <p class="card-text">It's seems that your booking hold has expired,
        please contact the owner of this link to get a new one.</p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'LinkExpired'
}
</script>
<style>

.card-img-top-linked-expired{
  margin-bottom:3%;
  max-width: 50%;
  max-height: 30%;
}
</style>
