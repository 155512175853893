// Contains the basic structure of the property
<template>
  <div>
  <div class="property_header_container" v-if="prop_loaded">
    <div v-if="property_fetcher.banner">
      <img v-if="event_selected.bookingEventConfiguration !== undefined" :src="event_selected.bookingEventConfiguration.banner"  class="img-fluid banner-container" alt="event banner">
      <img v-else :src="property_fetcher.banner" class="img-fluid banner-container" alt="event banner">
    </div>
    <div v-if="property_fetcher.banner">
      <div class="row mt-4 " style="text-align: left; margin-left: 1rem;">
        <p><strong>Para informacion adicional por favor contactarnos | For additional information please contact us: </strong></p>
        <div class='col-md-4'>
          <p>
            <strong>Email:</strong> {{property_fetcher.email}}
          </p>
          <p> <strong>Tel:</strong> {{property_fetcher.phone_number}}</p>
        </div>
    </div>
    </div>
    <div class="row" v-else>
        <div class='col-md-6'>
            <h2 class='property_name_header'>
                {{property_fetcher.name}}
            </h2>
            <div class="row" style="text-align: left;">
              <div class="col-md-12">
                  <small>{{ collaborator.employee_code }}</small> - <small>{{ collaborator.name }}</small>
              </div>
              <div>
                  <small>{{ collaborator.email }}</small>
              </div>
            </div>
        </div>
        <div class='col-md-6'>
            <p>
                {{property_fetcher.email}}
            </p>
            <p> <strong>Tel:</strong> {{property_fetcher.phone_number}}</p>
        </div>
    </div>
  </div>
  <div class="modal modal-lg fade" id="timer_modal" data-bs-backdrop="static" >
    <div class='modal-dialog modal-dialog-centered'>
      <div class="modal-content">
        <div class="modal-header">
            <i class="bi-x-circle" data-bs-dismiss='modal'></i>
        </div>
        <div class="modal-body container" style="text-align: center;">
            <i class="pi pi-info-circle" style="font-size: 130px; color: #81D4FA;" ></i>
            <br/>
            <br/>
            <h5>
              Your booking will expire soon! <br/>
              Do you want to continue?
            </h5>
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger" :onClick="cancelBooking">
              No
          </button>
          <button class="btn btn-success" :onClick="renewBookingHold">
              Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import ModalDialog from './modalDialog.vue';

export default {
    name: 'PropertyHeader',
    components: {
      ModalDialog,
    },
    methods: {
      ...mapActions(['renewBookingHold', 'cancelBookingHold', 'cancelBooking','resetBookingInfo']),
      async renewBookingHold(){
        await this.$store.dispatch('renewBookingHold').then((response)=> {
          if (document.getElementById('modal_btn')?.classList.contains('active')) {
            document.getElementById('modal_btn').click();
            document.getElementById('modal_btn').classList.remove('active');
          }
          if(response.status){

            this.$toast.add({severity:'success',
                                            summary:'Booking completion timer successfully updated!',
                                            detail: response.message,
                                            life:7000})
          } else {
            // here we use toastr for displaying the error
            this.$toast.add({severity:'error',
                                            summary:'Error Message',
                                            detail: response.message,
                                            life:7000})
          }

        })
      },
      cancelBooking(){
        this.$store.dispatch('cancelBooking');
        this.$store.dispatch('resetBookingInfo');
            if (document.getElementById('modal_btn')?.classList.contains('active')) {
                    document.getElementById('modal_btn').click();
                    document.getElementById('modal_btn').classList.remove('active');
            }
            this.$toast.add({severity:'success',
                             summary:'Booking Canceled',
                             detail: "Your booking has been successfully canceled",
                                         life:7000})
            this.$router.push({name:'property_event_list',
                               prop_id:this.prop_token});
      }
    },
    computed: mapGetters(['property_fetcher', 'prop_loaded', 'holdTimer', 'collaborator', 'event_selected'])
}
</script>

<style scoped>
.property_name_header{
    text-align: left;
    /* margin-left: 0%; */
}
 .property_header_container{
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 6px;
    border: 0px solid rgba( 255, 255, 255, 0.18 );
    padding: 0.1rem;
    margin-bottom: 11px;
}
 .banner-container {
   border-radius: 6px;
 }
</style>
